<template>
  <PDialog 
    :title="dialogTitle" 
    :classes="containerOrderId ? 'w-4/5 xl:w-3/5' : 'w-auto'"  
    :loading="isLoading"
    @close="onCancel"    
  >
    <div class="flex-1 flex overflow-y-hidden">

      <div class="flex-0 p-4 overflow-y-auto"
        :class="{
          'w-96': canEdit,
          'w-64': !canEdit
        }"
      >

        <div v-if="canEdit" class="flex flex-col space-y-6">
            
          <PSelect
            label="Tjeneste"
            :disabled="isSubmitting || isDeleting"
            :items="containerOrderTypes"
            itemText="name"
            itemValue="id"
            v-model="containerOrder.containerOrderTypeId"
            placeholder="Velg tjeneste"
          />

          <PDatePicker
            label="Dato"
            :min="minDate"
            :disabled="isSubmitting || isDeleting"
            v-model="containerOrder.deliveryDate"
            :required="true"
          /> 

          <PInput
            label="Referanse"
            :disabled="isSubmitting || isDeleting"
            v-model="containerOrder.customerReference"
          />

          <PTextarea          
            label="Kommentar"
            :disabled="isSubmitting || isDeleting"
            v-model="containerOrder.customerComment"
          />

          <PFile
            class="w-full"
            label="Dokumentvedlegg"
            :disabled="isSubmitting || isDeleting"
            :multiple="true"
            @change="onFileChange"
          />

        </div>

        <div v-else class="flex flex-col space-y-6">

          <PText label="Tjeneste">
            {{ containerOrder.containerOrderTypeName }}
          </PText>

          <PText label="Dato">
            {{ containerOrder.deliveryDate | date }}
          </PText>

          <PText label="Referanse">
            {{ containerOrder.customerReference || "-" }}
          </PText>

          <PText label="Kommentar">
            {{ containerOrder.customerComment || "-" }}
          </PText>

          <div>
            <div class="text-sm mb-1">Dokumentvedlegg</div>
            <p class="italic text-sm" v-if="containerDocuments.length === 0">Ingen.</p>
          </div>

        </div>

      </div>

      <div v-if="containerOrderId" class="flex-1 border-l border-gray-300 p-4 overflow-y-auto">


        <div class="text-sm" v-if="containerOrder.transactions.length === 0">
          <div class="mb-1">Hendelser</div>
          <p class="italic">Ingen hendelser er foreløpig registrert for valgt bestilling.</p>
        </div>

        <template v-else>

          <table class="transactions-table w-full text-sm">
            <thead>
              <tr>
                <th class="p-2 font-medium text-left">Type</th>
                <th class="p-2 font-medium text-left">Dato</th>
                <th class="p-2 font-medium">Faktura klar</th>
                <th class="p-2 font-medium">Fakturert</th>
                <th class="p-2 font-medium">Antall</th>
                <th class="p-2 font-medium text-right">Totalt</th>
              </tr>
            </thead>
            <tr 
              v-for="(transaction, index) in containerOrder.transactions" :key="index"
              class="hover:bg-orange-200"
              :class="{
                'bg-white': index % 2 === 0,
                'bg-gray-50': index % 2 !== 0
              }"
            >
              <td class="p-2 font-semibold">
                {{ transaction.transactionTypeName }}
              </td>
              <td class="p-2">
                {{ transaction.created | dateAndTime }}
              </td>
              <td 
                class="p-2 text-center"
                :class="{
                  'text-green-500': transaction.invoiceReady,
                  'text-red-500': !transaction.invoiceReady
                }"
              >                 
                {{ transaction.invoiceReady ? "Ja" : "Nei" }}
              </td>
              <td 
                class="p-2 text-center" 
                :class="{
                  'text-green-500': transaction.invoiced,
                  'text-red-500': !transaction.invoiced
                }"
              >
                {{ transaction.invoiced ? "Ja" : "Nei" }}
              </td>
              <td class="p-2 text-center">
                {{ transaction.quantity }}
              </td>
              <td class="p-2 text-right">
                {{ formatNumber(transaction.amount) }}
              </td>              
            </tr>
          </table>

          <!-- {{ containerOrder.transactions }} -->

        </template>
        

      </div>
    </div>    

    <PDialogActions :split="canDelete">

      <PLinkButton        
        class="text-sm"
        color="secondary"
        :icon="isDeleting ? 'spinner' : 'trash-alt'"
        :iconSpin="isDeleting"
        :disabled="isDeleting"
        v-if="canDelete"
        @click="onDelete">
        Slett bestilling
      </PLinkButton>
      
      <div class="flex space-x-2">
        
        <PButton          
          color="secondary"
          @click="onCancel"
          :disabled="isSubmitting || isDeleting"          
        >
          {{ this.containerOrderId ? "Lukk" : "Avbryt" }}
        </PButton>

        <PButton          
          @click="onSubmit"
          v-if="canEdit"
          :disabled="!canSubmit"
          :loading="isSubmitting"
        >
          {{ this.containerOrderId ? "Lagre" : "Bestill" }}
        </PButton>

      </div>
        
    </PDialogActions>
  </PDialog>
</template>

<script>

import http from "@/http"
import { 
  forEach,
  get,
  filter,
  pick 
} from "lodash"

import { format } from "date-fns"

export default {
  
  props: {
    containerRentalId: {
      type: String,
      required: true
    },
    containerOrderId: {
      type: String,
      required: false
    }
  },

  data () {
    return {
      containerRental: null,
      containerOrder: {
        id: "",
        deliveryDate: "",
        customerComment: "",
        customerReference: "",   
        containerTypeId: "",     
        containerOrderTypeId: "",
        transactions: [],
        status: ""
      },
      containerDocuments: [],
      containerOrderTypes: [],      
      isSubmitting: false,
      isDeleting: false,
      isLoading: false,
      files: [],
      minDate: format(new Date(), "yyyy-MM-dd")
    }
  },

  computed: {

    dialogTitle () {
      return this.containerOrderId || "Ny tjeneste"
    },

    canEdit () {
      return this.containerOrder.id === "" || this.containerOrder.status === "Registered"
    },

    canDelete () {
      return this.containerOrderId !== "" &&
             this.containerOrder.status === "Registered"
    },

    canSubmit () {
      return !!this.containerOrder.containerOrderTypeId &&
             !!this.containerOrder.deliveryDate &&
             !this.isDeleting
    }

  },

  methods: {

    formatNumber (number) {
      const num = number || 0
      return new Intl.NumberFormat(
        this.$i18n.locale, { 
          minimumFractionDigits: 2 
        }).format(num)
    },

    onFileChange (files) {
      this.files = files
    },

    onCancel () {
      this.$emit("close")
    },

    async onDelete () {

      this.isDeleting = true

      try {
        
        const promises = [
          http.delete(`ContainerOrder?id=${this.containerOrder.id}`)
        ]

        forEach(this.files, file => {
          promises.add(http.delete(`ContainerDocument?id=${file.id}`))
        })

        await Promise.all(promises)
        
        this.$emit("close")

      } catch (error) {
        this.$notifyError(error.reason)
      }

      this.isDeleting = false
    },

    async onSubmit () {

      this.isSubmitting = true

      try {

        let data = {
          containerRentalId: this.containerRentalId,
          containerTypeId: get(this.containerRental, "containerTypeId", ""),
          ...pick(this.containerOrder, [            
            "customerId",
            "locationId",
            "deliveryDate",
            //"containerTypeId",
            "customerComment",
            "customerReference",            
            "containerOrderTypeId"
          ])
        }

        if (this.containerOrderId) {
          data.id = this.containerOrderId
        }
        
        const containerOrder = await http.post("ContainerOrder", data)

        for (let file of this.files) {
          
          let formData = new FormData()
          
          formData.append("containerRentalId", this.containerRentalId)
          formData.append("containerOrderId", containerOrder.id)
          formData.append("containerId", get(this.containerRental, "containerId", ""))
          formData.append("comment", "")          
          formData.append("file", file)

          await http.post("ContainerDocument",
            formData, {
              headers: {
                  "Content-Type": "multipart/form-data"
              }
            }
          )
        }

        this.$emit("close")

      } catch (error) {
        this.$notifyError(error.reason)
      }

      this.isSubmitting = false
    }
  },

  async created () {
    
    this.isLoading = true    

    try {
      
      const [
        containerRental,
        containerOrderTypes
      ] = await Promise.all([
        http.get(`ContainerRental?id=${this.containerRentalId}`),
        http.get("ContainerOrderTypes")
      ])

      this.containerRental = containerRental

      this.containerOrderTypes = filter(containerOrderTypes, cot => {
        return cot.rentAction !== "Start"
      })
          
      if (this.containerOrderId) {        
        const [
          containerOrder,
          containerDocuments
        ] = await Promise.all([
          http.get(`ContainerOrder?id=${this.containerOrderId}&withTransactions=true`),
          http.get(`ContainerDocuments?containerOrderId=${this.containerOrderId}`),
        ])
        
        this.containerOrder = containerOrder
        this.containerDocuments = containerDocuments
      }

      this.isLoading = false

    } catch (error) {
      this.$notifyError(error.reason)
    }    

    this.isLoading = false

  }
}
</script>
